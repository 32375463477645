import { isArray } from '@pe-libs/react-components/build/lib/helper';
import useIntlMessage from '@pe-libs/react-components/build/lib/intl/hooks/useIntlMessage';
import { PageContext } from '@pe-libs/react-components/build/lib/store';
import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { getSearchUrl, getUrlWithoutParameters } from '#helpers/searchUrl';
import { globalSystemInfoRequested, setUserIntl } from '#modules/app/actions';
import { getUserLocale } from '#modules/app/selectors';
import { externalRatingCrawlerCountRequested } from '#modules/expertDirectory/actions';
import { convertToPhoneLocale } from '#modules/expertDirectory/components/CreateLead/helper';
import ExpertDirectory from '#modules/expertDirectory/components/Landing';
import { getNumberOfCustomerReviews } from '#modules/expertDirectory/selectors';
import { wrapper } from '../../store';
import { StateType } from '../../types';
import messages from '../messages';

type Props = {
  state: StateType;
  numberOfReviewsInMillions: number;
  locale: string;
  host?: string;
};

const ExpertDirectoryPage: NextPage<Props> = ({ locale, host, numberOfReviewsInMillions }) => {
  const formatMessage = useIntlMessage();

  const router = useRouter();

  useEffect(() => {
    if (!router.asPath.includes(getSearchUrl(locale))) {
      router.replace(`/${locale.toLowerCase()}/${getSearchUrl(locale)}/`);
    }
  });

  const urlWithoutParameters = getUrlWithoutParameters(router.asPath, host);

  return (
    <>
      <Head>
        <title>{formatMessage(messages.expertDirectoryStartPageSeoTitle)}</title>
        <link rel="canonical" href={urlWithoutParameters} />
        <meta
          name="description"
          content={formatMessage(messages.expertDirectoryStartPageSeoDescription, {
            year: new Date().getFullYear(),
            reviews: numberOfReviewsInMillions,
          })}
        />
      </Head>
      <ExpertDirectory />
    </>
  );
};

ExpertDirectoryPage.getInitialProps = wrapper.getInitialPageProps(
  (store) => async (context: PageContext<StateType>) => {
    const {
      query: { lang },
      res,
      req,
      asPath,
    } = context || {};
    const locale = isArray(lang) ? lang[0] : lang;

    if (res && !['de-de', 'en-us', 'en-gb', 'es-es', 'fr-fr', 'it-it', 'pt-pt', 'nl-nl', 'pl-pl'].includes(locale)) {
      res.writeHead(404);
      res.end();
    }

    await store.dispatch(globalSystemInfoRequested({ contextRequest: req }));
    await store.dispatch(externalRatingCrawlerCountRequested({ contextRequest: context.req }));

    store.dispatch(setUserIntl({ locale: convertToPhoneLocale(locale) }));

    if (res && asPath && !asPath.endsWith('/') && !asPath.includes('?')) {
      res.writeHead(301, { Location: `${asPath}/` });
      res.end();
    }

    const state = store.getState();

    const userLocale = getUserLocale(state)?.toLowerCase() ?? 'de-de';
    const numberOfReviewsInMillions = getNumberOfCustomerReviews(state);

    return { host: req?.headers.host, locale: userLocale, numberOfReviewsInMillions };
  },
);

export default ExpertDirectoryPage;
